<template>
  <div class="content md flex-box vertical">
    <div class="area">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical" label-align="left" class="flex-grow">
        <a-form-model-item label="入库类别" prop="category">
          <a-select placeholder="选择入库类别" :options="warehousingOptions" v-model="form.category"> </a-select>
        </a-form-model-item>
        <a-form-model-item label="入库日期" prop="warehouse_date" extra="仅允许选择当前及之前的日期">
          <a-date-picker
            v-model="form.warehouse_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            style="width: 100%;"
            :disabled-date="disabledDate"
            :allow-clear="false"
          />
        </a-form-model-item>
        <a-form-model-item class="info-list" prop="warehouse_add">
          <a-space>
            <a-button type="primary" @click="addInfo">添加入库商品</a-button>
            <a-button type="primary" @click="searchPurchase">申购单号入库</a-button>
          </a-space>
          <a-table
            :columns="columns"
            :data-source="form.warehouse_add"
            class="info-table"
            :row-key="(record) => record.key"
          >
            <div class="row-btn" slot="action" slot-scope="text, record, index">
              <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
              <a class="txt-btn danger" @click.stop="delRow(index)">删除</a>
            </div>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
    <BasicModal
      :title="`${edit ? '编辑' : '添加'}入库商品`"
      :initial="single"
      v-model="visible"
      :rules="editRules"
      :footer="edit ? 1 : 2"
      :success="addSuccess"
    >
      <template v-slot:edit="{ form }">
        <a-form-model-item label="商品名称" prop="goods_id" :extra="getCommodityExtra(form)">
          <GoodsSelect v-model="form.goods_id" :form="form" :options="goodsOptions" />
        </a-form-model-item>
        <a-form-model-item label="入库数量" prop="num">
          <a-input-number
            v-model="form.num"
            placeholder="请输入数字"
            :min="1"
            :step="1"
            allowClear
            style="width: 100%;"
            @change="updateTotalAmount($event, form)"
          ></a-input-number>
        </a-form-model-item>
        <!-- <a-form-model-item label="固定资产" :class="form.is_fixed_assets ? 'mb-0' : ''">
          <a-switch v-model="form.is_fixed_assets"></a-switch>
        </a-form-model-item> -->
        <a-form-model-item label="固定资产">
          <a-switch v-model="form.is_fixed_assets" @change="assetsChange($event, form)" />
          <a-select
            v-if="form.is_fixed_assets"
            placeholder="选择固定资产类型"
            :options="fixedAssetsOptions"
            v-model="form.fixed_assets_type"
          ></a-select>
        </a-form-model-item>
        <a-form-model-item label="商品单价（元）">
          <a-input-number
            v-model="form.unit_price"
            placeholder="请输入"
            :min="0"
            :step="0.01"
            allowClear
            style="width: 100%;"
            @change="updateTotalAmount($event, form)"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="合计金额（元）">
          <a-input-number
            v-model="form.price"
            placeholder="请输入"
            :min="0"
            :step="0.01"
            allowClear
            style="width: 100%;"
            disabled
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="交货人">
          <select-book type="user" v-model="form.delivered_by"></select-book>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="form.notes"
            placeholder="填写入库备注"
            :autoSize="{ minRows: 2, maxRows: 6 }"
            allowClear
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="附件">
          <form-upload
            v-model="form.annex"
            listType="text"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".excel,.word,.pdf"
            uploadTip="仅支持excel，word，pdf后缀的文件"
            save-file-name
          ></form-upload>
        </a-form-model-item>
      </template>
    </BasicModal>
    <BasicModal title="从申购单号导入" v-model="orderVisible" :rules="orderRules" :success="orderSuccess">
      <template v-slot:edit="{ form }">
        <a-form-model-item label="申购单号" prop="subscribe_num">
          <a-input v-model="form.subscribe_num" placeholder="请输入申购单号" allowClear></a-input>
        </a-form-model-item>
      </template>
    </BasicModal>
    <OrderModal v-model="infoVisible" :type="1" :form="form" :info="info" @success="resetFrom"></OrderModal>
  </div>
</template>
<script>
import moment from "moment";
import {
  clone,
  downLoadFile,
  getKeyTitle,
  getSuffix,
  isEmpty,
  randomString,
  stringConvertTimestamp,
} from "../../common/js/tool";
import BasicModal from "./components/BasicModal.vue";
import OrderModal from "./components/OrderModal.vue";
import GoodsSelect from "./components/GoodsSelect.vue";
import { warehousingOptions, fixedAssetsOptions } from "../../common/asset/type";

export default {
  name: "WarehousingAdd",
  components: {
    BasicModal,
    OrderModal,
    GoodsSelect,
  },
  data() {
    return {
      form: {
        category: 1,
        warehouse_date: new Date().pattern("yyyy-MM-dd"),
      },
      rules: {
        category: [{ required: true, message: "请选择入库类别", trigger: "change" }],
        warehouse_date: [{ required: true, message: "请选择入库日期", trigger: "change" }],
        warehouse_add: [{ required: true, message: "请添加入库商品", trigger: "change" }],
      },
      warehousingOptions,
      fixedAssetsOptions,
      unitOptions: [],
      columns: [
        {
          title: "SKU",
          customRender: (text) => {
            return text?.goods?.sku || "-";
          },
        },
        {
          title: "商品名称",
          customRender: (text) => {
            return text?.goods?.name || "-";
          },
        },
        { title: "商品数量", dataIndex: "num" },
        {
          title: "固定资产",
          customRender: (text) => {
            const { is_fixed_assets, fixed_assets_type } = text;
            if (is_fixed_assets == 1) {
              return getKeyTitle(fixedAssetsOptions, fixed_assets_type, "value", "label");
            } else {
              return "-";
            }
          },
        },
        {
          title: "商品单价",
          customRender: (text) => {
            const { unit_price = 0, goods } = text;
            return `${unit_price}/${goods?.goodsUnit?.name || "-"}`;
          },
        },
        { title: "合计金额", dataIndex: "price" },
        {
          title: "交货人",
          dataIndex: "delivered_by",
          customRender: (text) => {
            return text ? <open-data type="userName" openid={text} /> : "";
          },
        },
        { title: "备注", dataIndex: "notes" },
        {
          title: "附件",
          dataIndex: "annex",
          customRender: (text) => {
            return text ? <a onclick={() => downLoadFile(text)}>{"文件" + getSuffix(text)}</a> : "";
          },
        },
        { title: "操作", dataIndex: "action", fixed: "right", width: 100, scopedSlots: { customRender: "action" } },
      ],
      visible: false,
      single: null,
      editIndex: undefined,
      editRules: {
        goods_id: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        unit: [{ required: true, message: "请选择商品单位", trigger: "change" }],
        num: [{ required: true, message: "请输入申购数量", trigger: "blur" }],
        // fixed_assets_id: [{ required: true, message: "请选择固定资产", trigger: "change" }],
      },
      /* 申购单号 */
      orderVisible: false,
      orderRules: {
        subscribe_num: [{ required: true, message: "请输入申购单号", trigger: "blur" }],
      },
      /* 申购单详情 */
      infoVisible: false,
      info: null,

      goodsOptions: [],
    };
  },
  computed: {
    edit() {
      return this.single;
    },
  },
  created() {},
  methods: {
    getCommodityExtra(form) {
      let res = null;
      if (form.goods_id) {
        res = `当前库存：${form.goods?.goods_num || 0}${form.goods?.goodsUnit?.name || ""}`;
      }
      return res;
    },
    // 更新合计金额
    updateTotalAmount(val, form) {
      if (!isEmpty(form.num) && !isEmpty(form.unit_price)) {
        this.$set(form, "price", form.num * form.unit_price);
      } else {
        this.$set(form, "price", 0);
      }
    },
    assetsChange(val, form) {
      if (val) {
        form.fixed_assets_type = fixedAssetsOptions[0].value;
      } else {
        form.fixed_assets_type = undefined;
      }
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    // 编辑
    editRow(record, index) {
      if (record.goods_id && record.goods) {
        const goods = record.goods;
        const option = { ...goods, label: goods.name, value: goods.id };
        this.goodsOptions = [option];
      }
      this.single = record;
      this.editIndex = index;
      this.visible = true;
    },
    delRow(index) {
      this.$confirm({
        title: "提示",
        content: `确定删除此商品吗？`,
        onOk: () => {
          this.form.warehouse_add.splice(index, 1);
        },
      });
    },
    resetFrom() {
      this.form = {
        category: 1,
        warehouse_date: new Date().pattern("yyyy-MM-dd"),
      };
    },
    // 保存
    handleSave() {
      let ruleForm = this.$refs.ruleForm;
      ruleForm.validate((flag) => {
        if (flag) {
          this.$confirm({
            title: "提示",
            content: `确定保存此入库单吗？`,
            onOk: () => {
              let form = clone(this.form);
              if (form.warehouse_date) {
                form.warehouse_date = stringConvertTimestamp(form.warehouse_date);
              }
              form.warehouse_add?.forEach((item) => {
                item.is_fixed_assets = item.is_fixed_assets ? 1 : 0;
                if (item.annex) {
                  item.annex = JSON.stringify(item.annex);
                }
              });
              this.$axios({
                url: "/admin/asset-warehouse/create-goods",
                method: "POST",
                data: form,
              }).then((res) => {
                if (res.error == 0) {
                  this.$message.success("添加成功！");
                  this.form.warehouse_add = null; // 清空商品列表
                } else {
                  this.$message.error(res.msg);
                }
              });
            },
          });
        }
      });
    },
    // 清除编辑参数
    clearEdit() {
      this.single = null;
      this.editIndex = undefined;
    },
    // 添加入库商品
    addInfo() {
      this.clearEdit();
      this.visible = true;
    },
    // 添加入库商品
    addSuccess(form, close) {
      this.$set(this.form, "warehouse_add", this.form.warehouse_add || []);
      if (form.delivered_by?.length > 0) {
        form.delivered_by = form.delivered_by[0];
      } else {
        form.delivered_by = null;
      }
      if (form.key) {
        // 编辑
        this.form.warehouse_add[this.editIndex] = { unit_price: 0, price: 0, ...form };
      } else {
        // 新增
        this.form.warehouse_add.push({ key: randomString(16), unit_price: 0, price: 0, ...form });
      }
      close();
    },
    // 申购单号入库
    searchPurchase() {
      this.orderVisible = true;
    },
    // 查找申购单
    orderSuccess(form, close) {
      this.$axios({
        url: "/admin/asset-subscribe",
        method: "GET",
        params: {
          "filter[subscribe_num]": form.subscribe_num,
          "filter[fixed_id][is]": "null",
        },
      }).then((res) => {
        const data = res.data;
        if (data.length > 0) {
          this.info = {
            subscribe_num: form.subscribe_num,
            list: data,
          };
          this.infoVisible = true;
          close();
        } else {
          this.$message.error("不存在该申购单号!");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info-table {
  margin-top: 12px;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
